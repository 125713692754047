import React from "react"
import Layout from "../../layouts/Layout"
import { Header } from "../../components/Header"
import { Grids } from "../../components/Grids"
import { useSitePortfolioEn } from "../../hooks/use-site-portfolio-en"

const IndexPage = () => {
  const edges = useSitePortfolioEn()

  const metadata = {
    title: "Nicolás Díaz · Full Stack Developer & UI Designer",
    description:
      "I am Frontend Developer & UI Designer. I’m really passionate about web design. I have a high knowledge and experience in different fields as frontend design, development, prototyping and interface design. I manage a set of specialized technologies like javascript, nodejs, npm, React, Nest.js and others.",
    cover: "/images/covers/cover-nicolascodes.png",
    type: "website",
  }

  return (
    <Layout metadata={metadata}>
      <Header
        title="Nicolás"
        content="I am Frontend Developer & UI Designer. I’m really passionate about web design. I have a high knowledge and experience in different fields as frontend design, development, prototyping and interface design. I manage a set of specialized technologies like javascript, nodejs, npm, React, Nest.js and others."
      />
      <Grids edges={edges} />
    </Layout>
  )
}
export default IndexPage
